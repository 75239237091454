import React, { memo } from "react";
import { isEqual } from "lodash";
import withHydrationOnDemand from "react-hydration-on-demand";
import { useTheme } from "styled-components";
import {
  AlignmentVerticalOption,
  IThemeState,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import ConditionalWrapper from "../../global/components/ConditionalWrapper/ConditionalWrapper";
import ImageWrapper from "../../global/components/ImageWrapper/ImageWrapper";
import { DEFAULT_IMAGE_WIDTH } from "../../global/components/ImageWrapper/ImageWrapper.methods";
import { getImageNameFromUrl, isPortalSide } from "../../helpers/helpers";
import { StyledBoxImage } from "../../settingsPatterns/boxes/BoxImage/StyledImage";
import { useLinkContentActions } from "../../shared/hooks/useLinkContentAction";
import {
  BoxImageContentSettingsScheme,
  BoxImageDisplaySettingsScheme,
  getBoxImageContentScheme,
} from "./BoxImage.scheme";
import { IBoxImageProps } from "./BoxImage.types";
import { isColorTransparent } from "@ecp-boxes/settingsPatterns/settingsPatterns.methods";
import Link from "next/link";
import { useTooltipContext } from "../../structure/Contexts/TooltipContext";
import useIsMobilePortal from "@ecp-boxes/shared/hooks/useIsMobilePortal";

const BoxImage: React.FC<IBoxImageProps> = (props) => {
  const { getHref, linkContentActions, getTarget, anyActionIsActive } =
    useLinkContentActions(props.path);
  const { setTooltipData, resetTooltipData } = useTooltipContext();
  const isMobile = useIsMobilePortal();

  const theme = useTheme() as IThemeState;
  const backgroundUrl = theme.advanceSettings.settings.backgroundUrl;

  const content = getBoxImageContentScheme(backgroundUrl).parse(props.content);
  const contentSettings = BoxImageContentSettingsScheme.parse(
    props.contentSettings
  );

  const tooltipSettings = contentSettings.tooltipContent;

  const handleSetTooltip = () => {
    if (tooltipSettings.isVisible && !isMobile) {
      setTooltipData({
        label: tooltipSettings.label,
        tooltipStyle: tooltipSettings.tooltipStyle,
        spacing: tooltipSettings.spacing,
        imageId: props.id,
      });
    }
  };

  const handleResetTooltip = () => {
    if (tooltipSettings.isVisible && !isMobile) {
      resetTooltipData();
    }
  };

  const displaySettings = BoxImageDisplaySettingsScheme.parse(
    props.displaySettings
  );

  const settings = { ...content, ...contentSettings, ...displaySettings };

  const imageLinkHref = getHref(content.imageLink);

  const isSvg = content.imageUrl.endsWith(".svg");

  if (content.imageUrl === "" && !isPortalSide()) {
    return <div>No content to display</div>;
  }

  return (
    <StyledBoxImage
      $settings={settings}
      data-testid="BoxImage"
      className="image-container image-custom-style"
    >
      {
        <ConditionalWrapper
          condition={anyActionIsActive(content.imageLink)}
          wrapper={(children) => (
            <ConditionalWrapper
              condition={getHref(content.imageLink) !== undefined}
              wrapper={(children1) => (
                <Link
                  style={{
                    height: "100%",
                    position: "relative",
                    width: "100%",
                    flexShrink: "1",
                    cursor: "pointer",
                  }}
                  href={imageLinkHref ?? ""}
                  target={getTarget(content.imageLink)}
                  onClick={() => linkContentActions(content.imageLink)}
                >
                  {children1}
                </Link>
              )}
            >
              <span
                style={{
                  height: "100%",
                  position: "relative",
                  width: "100%",
                  flexShrink: "1",
                  cursor: "pointer",
                }}
                onClick={() => linkContentActions(content.imageLink)}
              >
                {children}
              </span>
            </ConditionalWrapper>
          )}
        >
          {isSvg &&
          !isColorTransparent(
            contentSettings.imageColor.color,
            theme.colorPalette
          ) ? (
            <div
              role="icon"
              aria-label={
                content.seoDescription
                  ? content.seoDescription
                  : getImageNameFromUrl(content.imageUrl)
              }
              className="svg image-container__svg"
              data-tooltip-id={`tooltip-${props.id}`}
              data-tooltip-place="top"
              data-tooltip-position-strategy="fixed"
              onMouseEnter={handleSetTooltip}
              onMouseLeave={handleResetTooltip}
            />
          ) : content.imageUrl !== "" && settings.imageSize.height !== 0 ? (
            <div className="imageContainer image-container__image">
              <ImageWrapper
                imageUrl={content.imageUrl}
                seoDescription={content.seoDescription}
                imageFit={!settings.imagePlacement}
                imageAlignment={{
                  horizontal: settings.contentAlignment,
                  vertical: AlignmentVerticalOption.CENTER,
                }}
                opacity={settings.imageOpacity}
                width={DEFAULT_IMAGE_WIDTH}
                height={settings.imageSize.height ?? 0}
                handleSetTooltip={handleSetTooltip}
                handleResetTooltip={handleResetTooltip}
                tooltipEnabled={tooltipSettings.isVisible && !isMobile}
                imageId={props.id}
              />
            </div>
          ) : (
            <div></div>
          )}
        </ConditionalWrapper>
      }
    </StyledBoxImage>
  );
};
const MemoizedBoxImage = memo(BoxImage, isEqual);

export default withHydrationOnDemand({ on: ["visible"] })(MemoizedBoxImage);
