import { z } from "zod";
import { HoverEffects } from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import {
  ColorReadoutValueSchemeBlackDefault,
  SpacingScheme,
  TooltipGlobalObjectSchema,
  TooltipReadoutValueScheme,
} from "@ecp-redux/dto/themeSettings/themeSettings.schemes";
import { AlignmentHorizontalOption } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { LinkContentScheme } from "../../settingsPatterns/contentPatterns.scheme";
import { SelectedGoToOption } from "../../settingsPatterns/contentPatterns.types";

export const BoxImageDisplaySettingsScheme = z
  .object({
    padding: SpacingScheme.default({
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    }).catch({ top: 0, right: 0, bottom: 0, left: 0 }),
    margin: SpacingScheme.default({
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    }).catch({ top: 0, right: 0, bottom: 0, left: 0 }),
  })
  .default({
    padding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    margin: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  })
  .catch({
    padding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    margin: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  });

export const BoxImageContentSettingsScheme = z
  .object({
    imageSize: z
      .object({
        auto: z.boolean().default(true).catch(true),
        height: z.number().nullable().default(null).catch(null),
      })
      .default({ auto: true, height: null })
      .catch({ auto: true, height: null }),
    imageOpacity: z.number().default(100).catch(100),
    imagePlacement: z.boolean().default(false).catch(false),
    imageColor: z
      .object({
        color: ColorReadoutValueSchemeBlackDefault,
        hoverColor: ColorReadoutValueSchemeBlackDefault,
      })
      .default({
        color: { id: "color$$1", custom: "#000000" },
        hoverColor: { id: "color$$1", custom: "#000000" },
      }),
    contentAlignment: z
      .nativeEnum(AlignmentHorizontalOption)
      .default(AlignmentHorizontalOption.CENTER)
      .catch(AlignmentHorizontalOption.CENTER),
    imageHover: z
      .nativeEnum(HoverEffects)
      .default(HoverEffects.NONE)
      .catch(HoverEffects.NONE),
    tooltipContent: z
      .object({
        isVisible: z.boolean().default(false).catch(false),
        label: z.string().default("Go to the page").catch("Go to the page"),
        tooltipStyle: TooltipReadoutValueScheme,
        spacing: z.number().default(5).catch(5),
      })
      .default({
        isVisible: false,
        label: "Go to the page",
        tooltipStyle: {
          id: "tooltip$$1",
          custom: TooltipGlobalObjectSchema.parse({}),
        },
        spacing: 5,
      })
      .catch({
        isVisible: false,
        label: "Go to the page",
        tooltipStyle: {
          id: "tooltip$$1",
          custom: TooltipGlobalObjectSchema.parse({}),
        },
        spacing: 5,
      }),
  })
  .default({
    imageSize: { auto: true, height: null },
    imageOpacity: 100,
    imagePlacement: false,
    imageColor: {
      color: { id: "color$$1", custom: "#000000" },
      hoverColor: { id: "color$$1", custom: "#000000" },
    },
    contentAlignment: AlignmentHorizontalOption.CENTER,
    imageHover: HoverEffects.NONE,
    tooltipContent: {
      isVisible: false,
      label: "Go to the page",
      tooltipStyle: {
        id: "tooltip$$1",
        custom: TooltipGlobalObjectSchema.parse({}),
      },
      spacing: 5,
    },
  })
  .catch({
    imageSize: { auto: true, height: null },
    imageOpacity: 100,
    imagePlacement: false,
    imageColor: {
      color: { id: "color$$1", custom: "#000000" },
      hoverColor: { id: "color$$1", custom: "#000000" },
    },
    contentAlignment: AlignmentHorizontalOption.CENTER,
    imageHover: HoverEffects.NONE,
    tooltipContent: {
      isVisible: false,
      label: "Go to the page",
      tooltipStyle: {
        id: "tooltip$$1",
        custom: TooltipGlobalObjectSchema.parse({}),
      },
      spacing: 5,
    },
  });

export const getBoxImageContentScheme = (url: string) => {
  return z
    .object({
      imageUrl: z.string().default(url).catch(url),
      imageLink: LinkContentScheme.default({
        showLinkOptions: true,
        selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
        goToRowSetting: { rowId: null },
        goToPageSetting: { link: "", openInNewTab: false },
      }).catch({
        showLinkOptions: true,
        selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
        goToRowSetting: { rowId: null },
        goToPageSetting: { link: "", openInNewTab: false },
      }),
      seoDescription: z.string().default("").catch(""),
      openInTab: z.boolean().default(false).catch(false),
    })
    .default({
      imageUrl:
        "https://preprod-minio-ecp.nd0.pl/ecp-media-tenant-b2b-demo/pop%20zaslepka%200.png",
      imageLink: {
        showLinkOptions: true,
        selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
        goToRowSetting: { rowId: null },
        goToPageSetting: { link: "", openInNewTab: false },
      },
      seoDescription: "",
      openInTab: false,
    })
    .catch({
      imageUrl:
        "https://preprod-minio-ecp.nd0.pl/ecp-media-tenant-b2b-demo/pop%20zaslepka%200.png",
      imageLink: {
        showLinkOptions: true,
        selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
        goToRowSetting: { rowId: null },
        goToPageSetting: { link: "", openInNewTab: false },
      },
      seoDescription: "",
      openInTab: false,
    });
};
